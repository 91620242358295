<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
      :btnFlag="{ addShow: true, delShow: false }"
    >
      <div slot="toolSub"></div>
      <!-- <div class="search" slot="tool">
        <el-input
          placeholder="按名称查询"
          v-model="searchQuery.name"
          size="small"
          class="searchInput"
        >
          <template slot="prepend">名称</template>
        </el-input>

        <el-input
          placeholder="按类型查询"
          v-model="searchQuery.type"
          size="small"
          class="searchInput"
        >
          <template slot="prepend">类型</template>
        </el-input>
      </div> -->
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    let self = this;
    return {
      remote: api.common,
      apiName: "/systemDataDictionary",
      searchVal: "",
      statusList: [
        { label: "成功", value: 0 },
        { label: "失败", value: 1 },
      ],

      searchQuery: { jobName: "", jobCode: "", status: "" },
      rules: {
        //传递给APPlist的表单验证
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],
        type: [
          {
            required: true,
            message: "请输入类型",
            trigger: ["blur", "change"],
          },
        ],
      },
      props: [
        {
          label: "名称",
          prop: "name",
          align: "center",
          width: 200,
        },
        {
          label: "ID",
          prop: "id",
          align: "center",
          width: 200,
        },
        {
          label: "类型",
          prop: "type",
          align: "center",
          width: 200,
        },

        {
          label: "排序",
          prop: "seqNo",
          align: "center",
          width: 100,
        },

        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
          width: 200,
        },

        {
          label: "备注",
          prop: "remark",
          align: "center",
        },
        {
          label: "操作",
          align: "center",
          type: "operationLink",
          width: 300,
          formatter: function(row) {
            const buttonList = [
              {
                text: "内容",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-tickets",
                type: "success",

                handler: function() {
                  self.jumpPage(row.id);
                },
              },
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "update",

                handler: function() {},
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "名称",
          prop: "name",
          type: "input",
        },
        {
          label: "类型",
          prop: "type",
          type: "input",
        },
        {
          label: "排序",
          prop: "seqNo",
          type: "input",
        },
        {
          label: "备注",
          prop: "remark",
          type: "input",
        },
      ],
    };
  },
  created() {},
  methods: {
    //同步默认商品到商户

    jumpPage(id) {
 this.$router.push({path:'/system/dictionaryDetails',query: {id:id}})
 
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
